module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NL Genome Project","short_name":"NLGP","start_url":"/","background_color":"#ffffff","theme_color":"#00b0ed","display":"minimal-ui","icons":[{"src":"/favicons/favicon-512.svg","sizes":"512x512","type":"image/svg"},{"src":"/favicons/favicon-180.svg","sizes":"180x180","type":"image/svg"},{"src":"/favicons/favicon-167.svg","sizes":"167x167","type":"image/svg"},{"src":"/favicons/favicon-152.svg","sizes":"152x152","type":"image/svg"},{"src":"/favicons/favicon.svg","sizes":"32x32","type":"image/svg"},{"src":"/favicons/favicon-57.svg","sizes":"57x57","type":"image/svg"},{"src":"/favicons/iphone-favicon-120x120.svg","sizes":"120x120","type":"image/svg"},{"src":"/favicons/ipad-favicon-76x76.svg","sizes":"76x76","type":"image/svg"},{"src":"/favicons/NLGP-Favicon.ico","sizes":"167x167","type":"image/x-icon"},{"src":"/favicons/NLGP-Favicon.ico","sizes":"180x180","type":"image/x-icon"},{"src":"/favicons/NLGP-Favicon.ico","sizes":"32x32","type":"image/x-icon"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-80281190-3","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
